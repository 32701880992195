import { ActionDataWidgets } from '../types'
import {
  CUSTOM_CRM_TERMS_MAX,
  CUSTOM_CRM_TERMS_MAX_ERROR,
  DESCRIPTION_MAX,
  DESCRIPTION_MAX_ERROR,
  TITLE_MAX,
  TITLE_MAX_ERROR
} from '../validation'
import {
  ContactCollectionFields,
  ContactCollectionForm,
  ContactCollectionFormDisplayTypes,
  FormFields,
  UseContactCollectionFormStateType
} from './types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { MaskType } from '@dtx-company/shared-components/src/types'
import { RootPageState } from '@dtx-company/inter-app/src/redux/types'
import { buildActionData } from './utils'
import { defaultWidgetLink } from '../../../constants'
import { getValidatedActionData } from '../typeUtils'
import { hasMailchimp } from '@dtx-company/inter-app/src/redux/slices/pageEditor/mailchimpEditing'
import { setClientHasMailchimpKey } from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useDispatch } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form-deprecated'
import { useLinkThemeState } from '../components/LinkStyle/hooks/useLinkThemeState'
import { useOpenProUpgradeModal } from '@app/code/src/components/ProUpradeModal/ProUpgradeModal.hooks'
import { usePageSelector } from '@dtx-company/inter-app/src/hooks/usePageSelector'
import { useSetValueWithPreview } from '../utils'
import { useSubmitWidget } from '../submitUtils'
import { v4 as uuid } from 'uuid'

export const useContactCollectionFormState = (
  order: number,
  curr: LinkType | undefined,
  handleClose: () => void
): UseContactCollectionFormStateType => {
  const isNewForm = curr == null
  const id = curr ? curr.id : uuid()
  const validate = ({
    displayName,
    email,
    phone,
    address,
    age,
    gender,
    custom,
    custom2
  }: ContactCollectionFields): boolean | string => {
    if (!(email || phone || address || displayName || custom || custom2 || age || gender)) {
      return 'Please choose at least one field to collect'
    }
    return true
  }

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const actionData = getValidatedActionData<'contactCollection'>(curr?.actionData, 'fields')
  const { hasOperation, isAuthenticated } = useAuthState()
  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    errors,
    clearErrors,
    setError,
    watch
  } = useForm({
    defaultValues: {
      title: curr?.title || '',
      description: curr?.description || '',
      thumbNailImgUrl: curr?.thumbNailImgUrl || '',
      fields: {
        displayName: isNewForm ? true : actionData?.fields.includes('dname') || false,
        email: isNewForm ? true : actionData?.fields.includes('email') || false,
        phone: actionData?.fields.includes('phone') || false,
        address: actionData?.fields.includes('address') || false,
        age: actionData?.fields.includes('age') || false,
        gender: actionData?.fields.includes('gender') || false,
        custom: actionData?.fields.includes('custom') || false,
        custom2: actionData?.fields.includes('custom2') || false
      },
      required: {
        displayName: isNewForm ? true : (actionData?.required ?? []).includes('dname') || false,
        email: isNewForm ? true : (actionData?.required ?? []).includes('email') || false,
        phone: (actionData?.required ?? []).includes('phone') || false,
        address: (actionData?.required ?? []).includes('address') || false,
        age: (actionData?.required ?? []).includes('age') || false,
        gender: (actionData?.required ?? []).includes('gender') || false,
        custom: (actionData?.required ?? []).includes('custom') || false,
        custom2: (actionData?.required ?? []).includes('custom2') || false
      },
      customFieldLabel: actionData?.customFieldLabel || '',
      customFieldLabel2: actionData?.customFieldLabel2 || '',
      customTerms: actionData?.customTerms
        ? { ...actionData.customTerms, ...(actionData?.link && { pdf: actionData?.link }) }
        : { enabled: false },
      zapier: actionData?.zapier || { enabled: false },
      klaviyo: actionData?.klaviyo || { enabled: false },
      hubspot: actionData?.hubspot || { enabled: false, apiKey: '' },
      salesforce: actionData?.salesforce || { enabled: false, apiKey: '' },
      mailChimp: actionData?.mailChimp || { enabled: false },
      googleSheets: actionData?.googleSheets || { enabled: false },
      widgetDisplayType: actionData?.widgetDisplayType ?? MaskType.NO_IMAGE,
      displayType: curr?.displayType || ContactCollectionFormDisplayTypes.EMBED,
      successMessage: curr?.actionData.successMessage || { enabled: false },
      filledOutFormRequirement: curr?.actionData.filledOutFormRequirement || false
    }
  })
  const dispatch = useDispatch()
  const submitWidget = useSubmitWidget()
  const { pageId } = usePageSelector((state: RootPageState) => {
    return {
      pageId: state.pageEditorReducer.pageId
    }
  })
  useEffect(() => {
    const getHasMailchimp = async (): Promise<void> => {
      const resp = await hasMailchimp(pageId ?? '')
      const mcKey: string = resp ? (resp as string) : ''

      //clear out redux slice if user goes to new page
      dispatch(setClientHasMailchimpKey({ apiKey: mcKey }))
    }
    getHasMailchimp()
  }, [pageId, dispatch])

  const onSubmit = async (props: ContactCollectionForm): Promise<void> => {
    const actionData = buildActionData(props)
    if (props.salesforce?.enabled && !(props.required?.displayName && props.fields.displayName)) {
      setError(FormFields.FIELDS, {
        message: 'Name is required for Salesforce collection',
        type: 'error'
      })
      return
    }
    if ((props.mailChimp.url ?? '').length > 0) {
      dispatch(setClientHasMailchimpKey({ apiKey: props.mailChimp.url ?? '' }))
    }
    await submitWidget({
      curr,
      actionData,
      handleClose,
      widgetType: (curr ? curr.type : 'contactCollection') as ActionDataWidgets,
      image: props.thumbNailImgUrl,
      linkTheme,
      fields: {
        title: props.title,
        description: props.description,
        id,
        order,
        displayType: props.displayType
      }
    })
  }
  const watchAll = watch()

  useEffect(() => {
    register(FormFields.TITLE, {
      required: true,
      maxLength: {
        value: TITLE_MAX,
        message: TITLE_MAX_ERROR
      }
    })
    register(FormFields.IMAGE)
    register(FormFields.DISPLAY_TYPE, {
      required: true
    })
    register(FormFields.WIDGET_DISPLAY_TYPE)
    register(FormFields.DESCRIPTION, {
      maxLength: {
        value: DESCRIPTION_MAX,
        message: DESCRIPTION_MAX_ERROR
      }
    })
    register(FormFields.FIELDS, {
      validate: (value: ContactCollectionFields): string | boolean => validate(value)
    })
    register(FormFields.REQUIRED)
    register(FormFields.CUSTOM_FIELD_LABEL, {
      required: !!watchAll.fields.custom,
      maxLength: {
        value: TITLE_MAX,
        message: TITLE_MAX_ERROR
      }
    })
    register(FormFields.CUSTOM_FIELD_LABEL_2, {
      required: !!watchAll.fields.custom2,
      maxLength: {
        value: TITLE_MAX,
        message: TITLE_MAX_ERROR
      }
    })
    register(FormFields.ZAPIER)

    register(FormFields.SALESFORCE, {
      validate: () => {
        return !errors.salesforce ? true : 'A Salesforce API key is required'
      }
    })
    register(FormFields.GOOGLESHEETS)
    register(`${FormFields.MAILCHIMP}.enabled`)
    register(`${FormFields.MAILCHIMP}.url`)
    register(FormFields.KLAVIYO)
    register(FormFields.HUBSPOT, {
      validate: () => {
        return !errors.hubspot ? true : 'A HubSpot API key is required'
      }
    })
    register(FormFields.CUSTOM_TERMS, {
      validate: ({ enabled, disclaimer, pdf }) => {
        if (enabled) {
          if (pdf || disclaimer) {
            if (disclaimer?.length > CUSTOM_CRM_TERMS_MAX) return CUSTOM_CRM_TERMS_MAX_ERROR
            return true
          }
          if (!disclaimer || !pdf)
            return 'Please provide a URL to, upload a PDF or write a disclaimer with your T&Cs'
          return true
        }
      }
    })
    register(FormFields.IMAGE)
    register(`${FormFields.SUCCESS_MESSAGE}.enabled`)
    register(`${FormFields.SUCCESS_MESSAGE}.header`, {
      required: !!watchAll.successMessage.enabled,
      maxLength: {
        value: TITLE_MAX,
        message: TITLE_MAX_ERROR
      }
    })
    register(`${FormFields.SUCCESS_MESSAGE}.body`, {
      maxLength: {
        value: DESCRIPTION_MAX,
        message: DESCRIPTION_MAX_ERROR
      }
    })
    register(FormFields.FILLED_OUT_FORM_REQ)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register])
  const disabled =
    watchAll.title.length === 0 ||
    !validate(watchAll.fields) ||
    (watchAll.fields.custom && watchAll.customFieldLabel === '') ||
    (watchAll.fields.custom2 && watchAll.customFieldLabel2 === '') ||
    Boolean(errors[FormFields.DISPLAY_TYPE]) ||
    !!errors.fields

  const edit = Boolean(curr)
  const showProUpsell = isAuthenticated ? hasOperation('view:pro_upsell_or_upgrade') : true
  const { handleProtectedActionClick } = useOpenProUpgradeModal({
    openProModalCondition: showProUpsell,
    lockModalVariant: LockModalVariants.FP_PRO_CRM_DISPLAY_TYPE,
    sourceId: 'contact-collection-widget'
  })
  const previewLink = useMemo(
    () => ({
      ...defaultWidgetLink,
      type: 'contactCollection',
      title: watchAll.title,
      id,
      linkTheme,
      thumbNailImgUrl: watchAll.thumbNailImgUrl,
      description: watchAll.description,
      displayType: watchAll.displayType,
      actionData: buildActionData(watchAll)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchAll, linkTheme]
  )
  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )
  return {
    id,
    handleSubmit,
    onSubmit,
    setValue,
    handleProtectedActionClick,
    watchAll,
    disabled,
    setLinkThemeValue,
    linkTheme,
    edit,
    errors,
    setError,
    clearErrors,
    showProUpsell
  }
}

export const shouldProGateThisCRMFeature = (hasFeatureAccess: boolean): boolean => {
  return !hasFeatureAccess
}

export const useCheckApiKey = ({
  checkAccountCallback,
  initApiKey
}: {
  checkAccountCallback: () => Promise<string | null>
  initApiKey: string | null
}): {
  existingApiKey: string | null
  loading: boolean
} => {
  const [existingApiKey, setExistingApiKey] = useState<string | null>(initApiKey ?? '')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAccountHasApiKey = async (): Promise<void> => {
      try {
        const apiKey = await checkAccountCallback()
        setExistingApiKey(apiKey ?? null)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    fetchAccountHasApiKey()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { existingApiKey, loading }
}
