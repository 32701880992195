import { CRMLabels } from '@dtx-company/true-common/src/types/crmThirdParty'
import {
  LockModalCoreProPlusDomains,
  LockModalFCCodeLimit,
  LockModalFCProCodeLimit,
  LockModalFPProFontStyles,
  LockModalFPProFonts,
  LockModalFPProHexColors,
  LockModalFPProMailchimpCRM,
  LockModalFPProPlusLinkLevelStyling,
  LockModalFPProPlusPageTemplates,
  LockModalFPProPlusZapierCRM,
  LockModalProGraphicBG,
  ProPlusFlowteams
} from '@app/code/src/assets'
import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { getPlanTypeName } from '../utils/getPlanTypeName'

const PRO = getPlanTypeName(PlanType.PRO)
const PRO_PLUS = getPlanTypeName(PlanType.PRO_PLUS_ORG)

export const LockModalCRMVariants: {
  [key in CRMLabels]?: LockModalVariants
} = {
  [CRMLabels.ZAPIER]: LockModalVariants.FP_PRO_PLUS_ZAPIER_CRM,
  [CRMLabels.MAILCHIMP]: LockModalVariants.FP_PRO_MAILCHIMP_CRM
}

/**
 * Graphic and more (the heading text) that is used for the LockModal
 */
export interface LockModalGraphic {
  header?: string
  customHeadingText?: string
  src?: string
  alt?: string
  height?: number
  width?: number
  bg?: string
  hideCompanies?: boolean
  lottie?: {
    url: string
  }
}

export const LockModalGraphics: {
  [key in LockModalVariants]?: LockModalGraphic
} = {
  [LockModalVariants.FP_PRO_FONTS]: {
    header: 'Unlock more fonts for advanced customization on Flowpage',
    src: LockModalFPProFonts,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FP_STYLED_FONTS]: {
    header:
      'Unlock additional color and font styling options for your links to better align your Flowpage with your brand',
    src: LockModalFPProFontStyles,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FP_PRO_PAGE_LEVEL_LINK_COLORS]: {
    // As of rn, same graphics and copy as FP_STYLED_FONTS
    header:
      'Unlock additional color and font styling options for your links to better align your Flowpage with your brand',
    src: LockModalFPProFontStyles,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FC_WHITELABEL]: {
    header: 'Remove the Flowcode branding on the eye of your Flowcode',
    src: '/graphics/lockModal/fc-whitelabel.svg',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FP_PRO_WHITELABEL]: {
    header: 'Take maximum control of your branding with Pro',
    src: '/graphics/lockModal/fp-pro-whitelabel-lg.svg',
    bg: LockModalProGraphicBG,
    lottie: {
      url: 'https://assets3.lottiefiles.com/packages/lf20_rysenydb.json'
    }
  },
  [LockModalVariants.FP_PRO_MAILCHIMP_CRM]: {
    header: 'Take automation to the next level with advanced integration capabilities',
    src: LockModalFPProMailchimpCRM,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FP_PRO_HEX_COLORS]: {
    header: 'Get access to advanced Flowpage design customizations with hex colors',
    src: LockModalFPProHexColors,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FP_PRO_PLUS_ZAPIER_CRM]: {
    header: 'Take automation to the next level with Zapier integration capabilities',
    src: LockModalFPProPlusZapierCRM,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FC_PRO_CODE_LIMIT]: {
    header: '',
    customHeadingText: `You have hit your Flowcode limit!`,
    src: '/graphics/lockModal/fc-pro-code-limit3-2x.png',
    height: 663,
    width: 305,
    bg: '#242424',
    hideCompanies: true
  },
  [LockModalVariants.FC_PRO_PLUS_CODE_LIMIT]: {
    header: 'Reach, engage & convert customers with more Flowcodes',
    src: LockModalFCProCodeLimit,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.ANALYTICS_CSV_DOWNLOAD]: {
    header: 'Download a CSV of your Flowcode and Flowpage analytics',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.ANALYTICS_PDF_DOWNLOAD]: {
    header: 'Download a PDF of your Flowcode and Flowpage analytics',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.ANALYTICS_TOP_CODES_CHART]: {
    header: 'See which codes performed well over your filtered time period',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.ANALYTICS_TOP_PAGES_CHART]: {
    header: 'See which pages performed well over your filtered time period',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.ANALYTICS_TOP_TAGS_CHART]: {
    header: 'See which tags performed well over your filtered time period',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.ANALYTICS_TOP_DESTINATIONS_CHART]: {
    header: 'See which scan destinations receive the most traffic',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.ANALYTICS_TRAFFIC_CHARTS]: {
    header: 'Understand how your links and codes performed over the week, UTM tracking, and more',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.ANALYTICS_TOP_LINKS_CHART]: {
    header: 'See which links performed well over your filtered time period',
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FP_LINK_LEVEL_STYLING]: {
    header:
      'Unlock advanced color and font styling options for individual links to better align your Flowpage with your brand',
    src: LockModalFPProPlusLinkLevelStyling,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FP_PRO_PLUS_CREATE_TEMPLATE]: {
    header: 'Pro Plus offers the best way to manage many pages at scale',
    bg: LockModalProGraphicBG,
    src: LockModalFPProPlusPageTemplates
  },
  [LockModalVariants.CORE_PRO_PLUS_DOMAINS]: {
    header: 'Take maximum control of your branding with Pro Plus',
    src: LockModalCoreProPlusDomains,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.PRO_PLUS_FLOWTEAMS]: {
    header: 'Collaborate on your QR projects with your team',
    customHeadingText: `Upgrade to ${PRO_PLUS} for Flowteams`,
    src: ProPlusFlowteams,
    bg: LockModalProGraphicBG
  },
  [LockModalVariants.FC_FP_NAV_GET_MORE_STORAGE]: {
    header: '',
    src: '/graphics/lockModal/fc-pro-code-limit3-2x.png',
    height: 663,
    width: 305,
    bg: '#242424',
    hideCompanies: true
  },
  [LockModalVariants.FC_FP_PRO_PLUS_NAV_GET_MORE_STORAGE]: {
    header: 'Upgrade to get even more Flowcodes and Flowpages',
    src: LockModalFCCodeLimit,
    bg: LockModalProGraphicBG
  }
}

export const LockModalCouponCodes: {
  [key in LockModalVariants]?: {
    subtext: string
    code: string
    gate: string
  }
} = {
  [LockModalVariants.FC_WHITELABEL]: {
    subtext: 'Get a special 20% off an annual plan with the code',
    code: '20offproplusannual',
    gate: ''
  },
  [LockModalVariants.FP_PRO_WHITELABEL]: {
    subtext: 'Get a special 20% off an annual plan with the code',
    code: '20offproplusannual',
    gate: ''
  }
}
