import { DemoModalVariants as DMV } from '../constants/demoModal'
import { DemoModalVariants } from '@dtx-company/true-common/src/types/demoModal'
import { FC } from 'react'
import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { TooltipContent } from '@dtx-company/design-system/src'
import { getPlanTypeName } from '../utils/getPlanTypeName'
import useTranslation from 'next-translate/useTranslation'

export const ProFeatureWrapperTooltip: FC<{
  tooltip?: {
    header: string
    body: string
  }
}> = ({ tooltip }) => <TooltipContent title={tooltip?.header} content={tooltip?.body} />

// `Pro` here is used to mean any paid feature across Pro & Pro+
export type ProFeatures = LockModalVariants | DemoModalVariants
export const ProFeatures = { ...LockModalVariants, ...DMV }

const PRO = getPlanTypeName(PlanType.PRO)
const PRO_PLUS = getPlanTypeName(PlanType.PRO_PLUS_ORG)

export const ProFeatureWrapperTooltips: {
  [key in ProFeatures]?: {
    header: string
    body: string
  }
} = {
  [ProFeatures.FC_WHITELABEL]: {
    header: `Upgrade to ${PRO_PLUS} to remove the Flowcode branding from the eye of your code.`,
    body: 'The privacy policy link at the bottom of the code will be retained for security purposes'
  },
  [ProFeatures.FP_PRO_FONTS]: {
    header: `${PRO} Feature: Additional Font Options`,
    body: `Upgrade to ${PRO} to access more font options for your Flowpage.`
  }
  // - Save this for an easier time.
  // [ProFeatures]: {
  //   header: '',
  //   body: ''
  // },
}

export const FlowpageCustomTermsTooltip: FC = () => {
  const { t } = useTranslation('page')
  return (
    <TooltipContent
      title={t('Forms.contactCollection.customTerms.tooltip.title', { plan: PRO })}
      content={t('Forms.contactCollection.customTerms.tooltip.content', { plan: PRO })}
    />
  )
}

export const FlowpageProDuplicationTooltip: FC = () => {
  const { t } = useTranslation('page')
  return (
    <TooltipContent
      title={t('Settings.duplicate.tooltip.title', { plan: PRO })}
      content={t('Settings.duplicate.tooltip.content', { plan: PRO })}
    />
  )
}

export const FlowpageProGoogleSheetsTooltip: FC = () => {
  const { t } = useTranslation('page')
  return (
    <TooltipContent
      title={t('Settings.contacts.tooltip.title', { plan: PRO })}
      content={t('Settings.contacts.tooltip.content', { plan: PRO })}
    />
  )
}
