import { Box } from '../Box'
import { FC } from 'react'
import { Icon } from '../Icon'
import { TagProps } from '../../../types/atoms/tag.types'
import { Text } from '../Text'
import { border, padding } from 'styled-system'
import styled from 'styled-components'

const TagBase = styled(Box)`
  display: inline-box;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 4px 12px;
  line-height: 14px;
  ${border}
  ${padding}
`

export const Tag: FC<TagProps> = ({
  color,
  bold,
  icon,
  iconAlt,
  label,
  ...props
}: TagProps): JSX.Element => {
  return (
    <TagBase {...props}>
      {icon && <Icon src={icon} alt={iconAlt} marginRight="8px" maxWidth="13px" />}
      <Text variant={bold ? 'tag/bold' : 'tag/regular'} color={color}>
        {label}
      </Text>
    </TagBase>
  )
}
