import { CRMLabels, CRMProFlexLabels } from '@dtx-company/true-common/src/types/crmThirdParty'
import { DemoModalVariants as DMV } from '@dtx-company/true-common/src/types/demoModal'
import { DemoModalHubspotCRM } from '@app/code/src/assets'
import { MarketingPageRoutes, Routes } from '@dtx-company/true-common/src/constants/routes'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { getPlanTypeName } from '../utils/getPlanTypeName'

export type DemoModalVariants = CRMProFlexLabels
export const DemoModalVariants = {
  ...CRMProFlexLabels
}

export const DemoModalCRMVariants: {
  [key in CRMLabels]?: DMV
} = {
  [CRMLabels.HUBSPOT]: DemoModalVariants.HUBSPOT
}

export const DemoModalDefaultButtonLabel = 'Book a demo'
export const DemoModalDefaultRedirectRoute = MarketingPageRoutes.CONTACT_SALES

const ProFlexPlanTypeName = getPlanTypeName(PlanType.PRO_FLEX)

export const DemoModalGraphics: Record<
  DMV,
  {
    version?: 'v1' | 'v2'
    header: string
    graphic: string
    footer?: string
    margin?: string
    buttonLabel?: string
    cancelButton?: boolean
    route?: Routes
  }
> = {
  [DemoModalVariants.HUBSPOT]: {
    header: `Upgrade to ${ProFlexPlanTypeName} to manage your CRM with Hubspot`,
    graphic: DemoModalHubspotCRM,
    footer:
      'Harness the power of excellent customer relationship manaegment with Hubspot integration.'
  }
}
